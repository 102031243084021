define(['santa-components', 'lodash', 'coreUtils', 'reactDOM', 'skins', 'htmlComponent/skins/skins.json', 'componentsCore'], function (santaComponents, _, coreUtils, ReactDOM, skinsPackage, skinsJson, componentsCore) {
    'use strict';

    const urlUtils = coreUtils.urlUtils;
    const OLD_DOMAIN = 'static.wix.com';

    function getIFrameSrc() {
        let url = this.props.compData.url;
        const isExternal = this.props.compData.sourceType === 'external';
        let htmlComponentTopologyUrl = this.props.serviceTopologyStaticHTMLComponentUrl;
        const currentProtocol = _.startsWith(this.props.serviceTopologyStaticHTMLComponentUrl, 'https') ? 'https:' : 'http:';

        if (!isExternal && coreUtils.stringUtils.startsWith(url, 'html/')) {
            url = htmlComponentTopologyUrl + url;
        }

        url = urlUtils.addProtocolIfMissing(url, currentProtocol);
        htmlComponentTopologyUrl = urlUtils.addProtocolIfMissing(htmlComponentTopologyUrl);

        if (currentProtocol === 'https:') {
            url = urlUtils.setProtocol(url, currentProtocol);
        }

        if (!isExternal) {
            url = url.replace('//static.wixstatic.com', htmlComponentTopologyUrl);

            if (_.includes(url, OLD_DOMAIN)) {
                const domain = _.head(url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n]+)/im));
                url = url.replace(domain, htmlComponentTopologyUrl);
            }
        }

        return url;
    }

    function hasContent(compData) {
        return !!compData.url;
    }


    function shouldAddSandboxAttr() {
        // return this.props.siteData.rendererModel && this.props.siteData.rendererModel.useSandboxInHTMLComp;
        return this.props.useSandboxInHTMLComp;
    }

    /**
     * @class components.HtmlComponent
     * @extends {core.skinBasedComp}
     */
    const htmlComponent = {
        displayName: 'HtmlComponent',
        mixins: [componentsCore.mixins.skinBasedComp],
        propTypes: {
            compData: santaComponents.santaTypesDefinitions.Component.compData,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp,
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen,
            serviceTopologyStaticHTMLComponentUrl: santaComponents.santaTypesDefinitions.ServiceTopology.staticHTMLComponentUrl,
            useSandboxInHTMLComp: santaComponents.santaTypesDefinitions.RendererModel.useSandboxInHTMLComp,
            ios: santaComponents.santaTypesDefinitions.BrowserFlags.ios.isRequired,
            registerComponentToPostMessage: santaComponents.santaTypesDefinitions.HtmlPostMessage.registerComponent.isRequired,
            unRegisterComponentFromPostMessage: santaComponents.santaTypesDefinitions.HtmlPostMessage.unRegisterComponent.isRequired,
            shouldResetComponent: santaComponents.santaTypesDefinitions.RenderFlags.shouldResetComponent.isRequired,
            isResponsive: santaComponents.santaTypesDefinitions.RendererModel.isResponsive,
            externalBaseUrl: santaComponents.santaTypesDefinitions.PublicModel.externalBaseUrl
        },

        getIframeAttributes() {
            const sandboxAttr = {
                sandbox: 'allow-same-origin allow-forms allow-popups allow-scripts allow-pointer-lock'
            };
            const defaultAttrs = {
                width: '100%',
                height: '100%',
                ref: 'iframe',
                name: 'htmlComp-iframe',
                scrolling: this.props.compData.scrolling || 'auto',
                'data-src': hasContent(this.props.compData) ? getIFrameSrc.call(this) : '',
                key: `iframe-${this.props.id}-${this.props.shouldResetComponent}`
            };

            // since responsive site doesn't have reLayout phase - data-src is not being replaced with src.
            if (this.props.isResponsive) {
                defaultAttrs.src = defaultAttrs['data-src'];
                delete defaultAttrs['data-src'];
            }

            if (this.props.compData.allowFullScreen) {
                _.assign(defaultAttrs, {
                    allowFullScreen: true
                });
            }
            if (_.get(this.props, 'compProp.isHidden')) {
                _.set(defaultAttrs, 'style.display', 'none');
            }

            return shouldAddSandboxAttr.call(this) ? _.assign(defaultAttrs, sandboxAttr) : defaultAttrs;
        },

        onLoad() {
            this.props.registerComponentToPostMessage(this.props.id, this.refs.iframe.contentWindow);
        },

        getInitialState() {
            return {
                $content: hasContent(this.props.compData) ? 'hasContent' : 'noContent'
            };
        },

        componentDidMount() {
            const iFrameNode = ReactDOM.findDOMNode(this.refs.iframe);
            if (iFrameNode) {
                iFrameNode.onload = this.onLoad;
            }
        },

        componentWillUpdate(nextProps) {
            if (nextProps.shouldResetComponent !== this.props.shouldResetComponent) {
                this.props.unRegisterComponentFromPostMessage(this.props.id);
            }
        },

        componentDidUpdate(prevProps) {
            if (prevProps.shouldResetComponent !== this.props.shouldResetComponent) {
                this.props.registerComponentToPostMessage(this.props.id, this.refs.iframe.contentWindow);
            }
        },

        componentWillUnmount() {
            this.props.unRegisterComponentFromPostMessage(this.props.id);
        },

        componentWillReceiveProps() {
            this.setState({
                $content: hasContent(this.props.compData) ? 'hasContent' : 'noContent'
            });
        },

        getSkinProperties() {
            const styles = {};
            const iframeAttributes = this.getIframeAttributes();

            // TODO: move this to the skin as a media query http://stephen.io/mediaqueries/
            // see also http://stackoverflow.com/questions/8037973/detect-different-device-platforms-using-css
            // on mobile safari, don't show scrolling
            // if (typeof window !== 'undefined' && window.navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
            if (this.props.ios() === true) {
                _.assign(styles, {overflow: 'scroll', 'WebkitOverflowScrolling': 'touch'});
            }

            const skinProperties = {
                '': {
                    style: styles,
                    'data-src': iframeAttributes['data-src'],
                    tagName: 'wix-iframe'
                },
                'iFrameHolder': {
                    children: [
                        santaComponents.utils.createReactElement('iframe', iframeAttributes)
                    ]
                }
            };

            if (this.props.isResponsive) {
                delete skinProperties['']['data-src'];
            }

            return skinProperties;
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.HtmlComponent', htmlComponent);
    skinsPackage.skinsMap.addBatch(skinsJson);

    return htmlComponent;
});
